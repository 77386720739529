<template>
  <div>
    <b-container>
      <Form />
    </b-container>
  </div>
</template>

<script>
import Form from './Form';

export default {
  components: {
    Form,
  },
};
</script>